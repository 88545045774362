import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }

        wordpressWpApiMenusMenusItems(name: { eq: "Menu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}

    render={data => (
      <header className="site-header">
        <div className="wrap">
          <h1 className="site-title">
            <Link to="/">
              {data.wordpressSiteMetadata.name}
            </Link>
          </h1>

          <ul className="site-header_nav">
            {data.wordpressWpApiMenusMenusItems.items.map(item => (
              <li key={item.object_slug} className="nav-item">
                <Link
                  to={`/${item.object_slug}`}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
